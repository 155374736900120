
/** Not found component for 404 handling
 *
 * Props:
 * - None
 *
 * States:
 * - None
 *
 * RoutesList -> NotFound
 */

function NotFound() {
  return (
    <div className="NotFound">
      <h1>404 Not Found</h1>
      <p>We were unable to find the page you wanted</p>
    </div>
  );
}

export default NotFound;